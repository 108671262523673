import router from '@/router';
import configs from '@/config'
import { useStore }  from 'vuex'
import { getToken, getCookie} from "@/utils/auth.js"

router.beforeEach((to, from, next) => {
    window.scrollTo(0,0);
    // const { state, dispatch} = useStore()
    if(getToken()) {
        // dispatch('loginGetInfo')
		if(configs.login_redirect.indexOf(to.path)>-1){
            if(getCookie('userStatus') == 0) {
                next({ path: '/userHome' })
            } else {
                next({ path: '/improve' })
            }
		} else {
			next();
		}
    } else {
		next();
    }
})