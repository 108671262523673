import { axios } from '@/utils/request'

/**
 * 邮箱发送验证码
 * @returns {emailVerifycode}
 */
export function emailVerifycode(data) {
    return axios({
        url: '/ipac-apic/email/verifycode',
        method: 'get',
        data: data
    })
}

/**
 * 用户账号登录
 * @returns {accountInfoLogin}
 */
export function accountInfoLogin(data) {
    return axios({
        url: '/ipa-web/account/info/login',
        method: 'post',
        data: data
    })
}
/**
 * 获取当前登录用户信息
 * @returns {getUserInfo}
 */
export function getUserInfo() {
    return axios({
        url: '/ipa-web/account/info/getUserInfo',
        method: 'get'
    })
}
/**
 * 账号退出
 * @returns {accountInfoLogout}
 */
export function accountInfoLogout(data) {
    return axios({
        url: '/ipa-web/account/info/logout',
        method: 'post',
        data: data
    })
}
/**
 * 密码重置
 * @returns {accountInfoResetPwd}
 */
export function accountInfoResetPwd(data) {
    return axios({
        url: '/ipa-web/account/info/resetPwd',
        method: 'get',
        data: data
    })
}
/**
 * 密码修改
 * @returns {accountInfoResetPwdNeedOld}
 */
export function accountInfoResetPwdNeedOld(data) {
    return axios({
        url: '/ipa-web/account/info/resetPwdNeedOld',
        method: 'get',
        data: data
    })
}
/**
 * 普通用户注册
 * @returns {memberInfoRegister}
 */
export function memberInfoRegister(data) {
    return axios({
        url: '/ipa-web/member/info/register',
        method: 'post',
        data: data
    })
}
/**
 * 普通用户完善信息
 * @returns {memberInfoPerfectUserInfo}
 */
export function memberInfoPerfectUserInfo(data) {
    return axios({
        url: '/ipa-web/member/info/perfectUserInfo',
        method: 'post',
        data: data
    })
}
/**
 * 普通用户获取信息
 * @returns {memberInfoByid}
 */
export function memberInfoByid(data) {
    return axios({
        url: '/ipa-web/member/info/byid',
        method: 'get',
        data: data
    })
}
/**
 * 管理单位注册
 * @returns {companyInfoRegister}
 */
export function companyInfoRegister(data) {
    return axios({
        url: '/ipa-web/company/info/register',
        method: 'post',
        data: data
    })
}
/**
 * 管理单位完善信息
 * @returns {companyInfoPerfectUserInfo}
 */
export function companyInfoPerfectUserInfo(data) {
    return axios({
        url: '/ipa-web/company/info/perfectUserInfo',
        method: 'post',
        data: data
    })
}

/**
 * 查询主管部门
 * @returns {sponsorInfoList}
 */
export function sponsorInfoList(data) {
    return axios({
        url: '/ipa-web/sponsor/info/list',
        method: 'get',
        data: data
    })
}
/**
 * 手动输入充值
 * @returns {rechargeMoney}
 */
export function rechargeMoney(data) {
    return axios({
        url: '/ipac-apic/recharge/inputMoney',
        method: 'post',
        data: data
    })
}
