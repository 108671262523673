import { useStore }  from 'vuex'
const myMixin = {
    data() {
        return {
			
        };
    },
    // computed: {
        // userInfo() {
            // const { state } = useStore()
            // return state.user.userInfo
        // }
    // },
    methods: {
        imgSrc(url) {
            if(url) {
                return process.env.VUE_APP_BASE_API + '/ipa-file/file/manager/fileid/' + url
            } else {
                return ''
            }
        }
    }
};
export default myMixin