<template>
	<div class="srach">
		<ul class="f-x">
			<li :key="index" @click="typePath=item.path" :class="{'serach-type':typePath==item.path}"
			v-for="(item, index) in queryTypeList">{{item.name}}</li>
		</ul>
		<div class="srach-box f-x-c">
			<input :class="{'isOne':typePath!='/inst'}" v-model="keyValue" placeholder="请输入关键词" />
			<el-button @click="mixSerach()">查 询</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			type: {
				type: String,
				default: '/inst'
			},
			keyword: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				keyValue: '',
                typePath: '',
				queryTypeList: [{
					path: '/inst',
					name: '科研仪器'
				}, {
					path: '/infrast',
					name: '科研设施'
				}, {
					path: '/servicepro',
					name: '服务项目'
				}, {
					path: '/comList',
					name: '管理单位'
				}, {
					path: '/newList',
					name: '新闻资讯'
				}]
			}
		},
		created() {
			this.keyValue = this.keyword,
            this.typePath = this.type
		},
		methods: {
			mixSerach() {
				if(this.typePath == this.$route.path) {
					// 当前页面，不用跳转
					this.$emit('serachKeyWord', this.keyValue)
				} else {
					this.$store.commit('setKeyWord', this.keyValue)
					this.$router.push({
					    path: this.typePath
					})
				}
			}
		}
	}
</script>

<style>
</style>