import { accountInfoLogin, getUserInfo} from "@/api/user"
import {getToken, setToken, setCookie} from "@/utils/auth.js"

import CompanyMenu  from '@/config/menu/company.js'
import EmployerMenu  from '@/config/menu/employer.js'
import MemberMenu  from '@/config/menu/member.js'
import SponsorMenu  from '@/config/menu/sponsor.js'

const user = {
    state: {
        token: '',
        loginType: '',
        status: '',         
        userName: '',
        menus: []
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_USERINFO: (state, userInfo) => {
            state.userInfo = userInfo
            state.loginType = userInfo.userType
        },
        SET_USERTYPE: (state, loginType) => {
            state.loginType = loginType
        },
        SET_USERNAME: (state, userName) => {
            state.userName = userName
        },
        SET_STATUS: (state, status) => {
            state.status = status
        },
        SET_MENUS: (state, perms) => {
            if(state.loginType == 4) {
                state.menus = MemberMenu;
            }else {
                state.menus = perms;
            }
            // if(userType == 1) { //单位
            //     state.menus = CompanyMenu;
            // }else if(userType == 2) { //主管部门
            //     state.menus = SponsorMenu;
            // }else if(userType == 3) { //员工
            //     state.menus = EmployerMenu;
            // }else if(userType == 4) { //普通用户
            //     state.menus = MemberMenu;
            // }
        }
    },
    actions: {
        SetMenu({commit}, userType){
            
        },
        Login({commit}, userInfo){
            return new Promise((resolve, reject) => {
                accountInfoLogin(userInfo).then(response => {
        			commit('SET_TOKEN', response.data.token)
        			commit('SET_USERTYPE', response.data.loginType)
                    commit('SET_STATUS', response.data.loginType)
                    setToken(response.data.token)
                    setCookie('userStatus', response.data.status)
                    resolve(response)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        LoginOut({commit}, userInfo){
            return new Promise((resolve, reject) => {
                commit('SET_TOKEN', '')
                commit('SET_USERTYPE', '')
                commit('SET_STATUS', '')
                setToken('')
                setCookie('userStatus', '')
            })
        },
        // 登录时获取用户信息
        loginGetInfo({commit}) {
            return new Promise((resolve, reject) => {
                getUserInfo().then(response => {
                    if (response.code == 200) {
						commit('SET_USERTYPE', response.data.userType)
						commit('SET_STATUS', response.data.userType)
                        commit('SET_USERINFO', response.data)
                        commit('SET_MENUS', response.data.webPerms)
                        resolve(response)
                    } else {
                        reject(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
    }
    
}

export default user