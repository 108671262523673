import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import "animate.css"
import elementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import './permission.js';
import mixin from './mixins/index.js';
import config from './config'
import MixSerach from '@/components/mixSerach'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component('MixSerach', MixSerach)
app.config.globalProperties.$config = config;
app.use(elementPlus, {locale: zhCn})
app.use(store)
app.use(router)

app.mixin(mixin)
app.mount('#app')
