import {
    createStore
} from 'vuex'
import user from './modules/user'
export default createStore({
    state: {
        keyword: ''
    },
    modules: {
        user
    },
    mutations: {
        setKeyWord: (state, keyword) => {
            state.keyword = keyword
        },
    },
    // actions: {
    //     //异步操作
    // },
    //getters相当于计算属性
    getters: {}
})