import Cookies from 'js-cookie'

export const TokenKey = 'user-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, 86400)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}


let paramsExpansion = {}
// 发布环境配置cookie地址，不然无法正常删除cookie
if(process.env.NODE_ENV === "production") {
    paramsExpansion = {path: '/', domain: '.gaizhanqu.com'}
}else {
	 paramsExpansion = {}
}
export function setCookie(key, value) {
    Cookies.set(key, value, {expires: 7, ...paramsExpansion})
}
export const getCookie = function(name) {
    return Cookies.get(name)
}
export function removeCookie(name) {
    Cookies.remove(name)
}