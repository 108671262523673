// 全局配置
var config = {
	login_redirect: ['/login','/register'],			// 登录后，未登录的页面重定向
	queryTypeList: [
		{
		    path: '/inst',
			name: '科研仪器'
		}, {
		    path: '/infrast',
			name: '科研设施'
		}, {
		    path: '/comList',
			name: '管理单位'
		}, {
		    path: '/newList',
			name: '新闻资讯'
		}
	]
}
export default config