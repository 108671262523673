import {
	createRouter,
	createWebHistory
} from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'index',
		component: () => import( /* webpackChunkName: "about" */ '../components/navPage'),
		redirect: '/home',
		children: [
			{
				path: '/home',
				name: 'home',
				component: () => import( /* webpackChunkName: "about" */ '../views/home'),
			}, 
			{
				path: '/comList',
				name: 'comList',
				component: () => import( /* webpackChunkName: "about" */ '../views/company/list'),
			}, 
			{
				path: '/comDetail',
				name: 'comDetail',
				component: () => import( /* webpackChunkName: "about" */ '../views/company/detail'),
			}, 
			{
				path: '/newList',
				name: 'newList',
				component: () => import( /* webpackChunkName: "about" */ '../views/news/list'),
			},
			{
				path: '/newInfo',
				name: 'newInfo',
				component: () => import( /* webpackChunkName: "about" */ '../views/newInfo/list'),
			}, 
			{
				path: '/newDetail',
				name: 'newDetail',
				component: () => import( /* webpackChunkName: "about" */ '../views/news/detail'),
			}, 
			{
				path: '/inst',
				name: 'instList',
				component: () => import( /* webpackChunkName: "about" */ '../views/instruments/list'),
			}, 
			{
				path: '/instDetail',
				name: 'instDetail',
				component: () => import( /* webpackChunkName: "about" */ '../views/instruments/detail'),
			}, 
			{
				path: '/infrast',
				name: 'infrastList',
				component: () => import( /* webpackChunkName: "about" */ '../views/infrastructure/list'),
			}, 
			{
				path: '/infrastDetail',
				name: 'infrastDetail',
				component: () => import( /* webpackChunkName: "about" */ '../views/infrastructure/detail'),
			},{
				path: '/order',
				name: 'order',
				component: () => import( /* webpackChunkName: "about" */ '../views/order/index'),
			},{
				path: '/serOrder',
				name: 'serOrder',
				component: () => import( /* webpackChunkName: "about" */ '../views/order/serviceOrder'),
			},{
                path: '/servicepro',
                name: 'servicepro',
                component: () => import( /* webpackChunkName: "about" */ '../views/servicepro/index'),
            },{
                path: '/serviceDetail',
                name: 'serviceDetail',
                component: () => import( /* webpackChunkName: "about" */ '../views/servicepro/detail.vue'),
            },
			{
				path: '/protocol',
				name: 'protocol',
				component: () => import( /* webpackChunkName: "about" */ '../views/other/protocol'),
			},
			{
				path: '/introduce',
				name: 'introduce',
				component: () => import( /* webpackChunkName: "about" */ '../views/introduce/index'),
			}
		]
	}, 
	{
		path: '/login',
		name: 'login',
		component: () => import( /* webpackChunkName: "about" */ '../views/user'),
	}, 
	{
		path: '/register',
		name: 'register',
		component: () => import( /* webpackChunkName: "about" */ '../views/user/register.vue'),
	}, 
	{
		path: '/improve',
		name: 'improve',
		component: () => import( /* webpackChunkName: "about" */ '../views/user/improve.vue'),
	},
	{
		path: '/userHome',
		name: 'userHome',
		redirect: '/userHome/home',
		component: () => import( /* webpackChunkName: "about" */ '../views/usercenter/Layout.vue'),
		children: [
			{
				path: '/userHome/home',
				name: 'UserCompanyHome',
				component: () => import('../views/usercenter/Main/Home/index.vue'),
			},
			{
				path: '/userHome/instruments/index',
				name: 'UserHomeInstrumentsIndex',
				component: () => import('../views/usercenter/Main/Instruments/index.vue'),
			},
			{
				path: '/userHome/instruments/drafts',
				name: 'UserHomeInstrumentsDrafts',
				component: () => import('../views/usercenter/Main/Instruments/drafts.vue'),
			},
			{
				path: '/userHome/infrastructure/index',
				name: 'UserHomeInfrastructureIndex',
				component: () => import('../views/usercenter/Main/Infrastructure/index.vue'),
			},
			{
				path: '/userHome/infrastructure/drafts',
				name: 'UserHomeInfrastructureDrafts',
				component: () => import('../views/usercenter/Main/Infrastructure/drafts.vue'),
			},
			{
				path: '/userHome/serviceItem/index',
				name: 'UserHomeServiceItemIndex',
				component: () => import('../views/usercenter/Main/ServiceItem/index.vue'),
			},
			{
				path: '/userHome/serviceItem/drafts',
				name: 'UserHomeServiceItemDrafts',
				component: () => import('../views/usercenter/Main/ServiceItem/drafts.vue'),
			},
			{
				path: '/userHome/order/index',
				name: 'UserHomeOrderIndex',
				component: () => import('../views/usercenter/Main/Order/index.vue'),
			},
			{
				path: '/userHome/order/my',
				name: 'UserHomeOrderMy',
				component: () => import('../views/usercenter/Main/Order/my.vue'),
			},
			{
				path: '/userHome/managementRule',
				name: 'UserHomeManagementRule',
				component: () => import('../views/usercenter/Main/ManagementRule/index.vue'),
			},{
				path: '/userHome/entrustInfo',
				name: 'EntrustInfo',
				component: () => import('../views/usercenter/Main/EntrustInfo/index.vue'),
			},
			// {
			//   path: '/userHome/serviceDomain',
			//   name: 'UserHomeServiceDomain',
			//   component: () => import('../views/usercenter/Main/ServiceDomain/index.vue'),
			// },
			{
				path: '/userHome/company',
				name: 'UserHomeCompany',
				component: () => import('../views/usercenter/Main/Company/index.vue'),
			},
			{
				path: '/userHome/employe',
				name: 'UserHomeEmploye',
				component: () => import('../views/usercenter/Main/Employe/index.vue'),
			},
			{
				path: '/userHome/userinfo',
				name: 'UserHomeUserInfo',
				component: () => import('../views/usercenter/Main/user/userinfo.vue'),
			},
			{
				path: '/userHome/setting',
				name: 'UserHomeSetting',
				component: () => import('../views/usercenter/Main/user/setting.vue'),
			}
		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router