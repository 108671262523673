export default [
    {
        url: '/userHome/home',
        label: '首页',
        icon: 'HomeFilled'
    },
    {
        url: '/userHome/order',
        label: '订单管理',
        icon: 'Goods',
        children: [
            {
                url: '/userHome/order/my',
                label: '我的订单',
                icon: 'Odometer',
            }
        ]
    }
]