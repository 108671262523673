import axios from 'axios'
import store from '@/store'
import router from '@/router'
import {getToken, setToken, removeToken, 
removeCookie} from "@/utils/auth.js"

import { ElNotification, ElMessage } from 'element-plus'

let apiBaseUrl = process.env.VUE_APP_BASE_API
const service = axios.create({
    baseURL: apiBaseUrl
})
// 请求前的处理
service.interceptors.request.use(config => {
	// console.log('------token', getToken())
    if (getToken()) {
        config.headers['satoken'] = getToken()
    }
    if (config.method != 'post') {
        config.params = config.data
    }
    return config
}, (error) => {
    return Promise.reject(error)
})
// 响应回来的处理
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code !== 200) {
            errors(response.data)
            return Promise.reject('error')
        } else {
            return response.data
        }
    },
    error => {
        ElNotification({
			title: '系统提示',
            message: "接口返回异常",
			type: 'error'
        })
        return Promise.reject(error)
    }
)

const errors = (data) => {
    switch (data.code) {
        case 401:
            // removeToken()
            removeCookie('userInfo')
            router.push('/')
            // ElMessage.error('未登录或登录已过期');
            break;
        case 402:
            ElMessage.error(data.msg || '操作失败');
            break;
        case 403:
            ElMessage.error(data.msg || '无权限');
            break;
        case 500:
            ElMessage.error('服务内部出错！');
            break;
    }
}
export {
    service as axios
}